<template>
  <v-dialog
    v-model="displayDialog"
    max-width="500px"
    width="unset"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-title class="justify-center text-uppercase headline">
        {{ revision.id !== undefined ? 'Update' : 'Create' }} revision
      </v-card-title>
      <v-divider />
      <div class="px-10 pb-5">
        <ant-input label="Reason">
          <template #input-field>
            <v-text-field
              v-model="revision.reason"
              dense
              filled
              hide-details
              placeholder="Reason"
              single-line
            />
          </template>
        </ant-input>
      </div>
      <v-card-actions class="ant-border-top ant-dialog-actions-bg">
        <v-spacer />
        <v-btn color="error" small text @click="closeDialog"> Cancel</v-btn>
        <v-btn color="primary" elevation="0" small @click="saveRevision">
          save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import AntInput from '@/components/AntInput.vue';

export default {
  name: 'RevisionDialog',
  components: { AntInput },
  props: {
    displayDialog: {
      type: Boolean,
      required: true,
    },
    table: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    revision: {},
  }),
  methods: {
    closeDialog() {
      this.revision = Object.assign({}, {});
      this.$emit('closeDialog');
    },
    saveRevision() {
      let body = {
        project: {
          id: this.table.project,
        },
        table: {
          id: this.table.id,
        },
        reason: this.revision.reason,
      };

      this.$store.dispatch('storeRevision', body).then(() => {
        this.closeDialog();
      });
    },
  },
};
</script>

<style scoped></style>
