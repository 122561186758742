<template>
  <div v-if="buildOrderStatus === 'success'">
    <portal to="ant-toolbar">
      <div></div>
    </portal>

    <div class="full-height d-flex overflow-hidden">
      <panel-resizable
        :collapsible="false"
        :default-width="250"
        :min-width="200"
        class="ant-glass-background radius-0 ant-border-right"
        side="left"
        @resize-end="$refs['forge-viewer'].viewerService.resizeView()"
      >
        <dynamic-data-table
          :clickable-rows="true"
          :table-headers="headers"
          :table-records="buildOrderData.CFFA_FORGE_BUILD_ORDER.records"
          hide-footer
          table-title=""
          @clickedRow="findObjectInModel"
        >
          <template #item.build_order="{ value, rowId, item }">
            <v-edit-dialog
              :return-value.sync="item.build_order"
              large
              @save="updateBuildOrder(item.id, item.build_order)"
            >
              {{ item.build_order }}
              <template #input>
                <v-text-field
                  v-model="item.build_order"
                  label="Edit"
                  single-line
                />
              </template>
            </v-edit-dialog>
          </template>
          <template #table-actions>
            <v-select
              :items="buildOrderRevisions"
              class="mr-5"
              clearable
              dense
              hide-details
              item-text="name"
              item-value="id"
              label="revisie"
              @change="fetchBuildOrderRevision"
              @click:clear="fetchModuleData"
            />
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  small
                  v-bind="attrs"
                  @click="resetBuildOrder"
                  v-on="on"
                >
                  <v-icon dense> mdi-cog-counterclockwise</v-icon>
                </v-btn>
              </template>
              <span>Reset build order</span>
            </v-tooltip>
          </template>
        </dynamic-data-table>
      </panel-resizable>

      <div class="flex-1">
        <ant-toolbar-container ant-toolbar-prefix="build-order" />
        <forge-viewer
          ref="forge-viewer"
          :client="buildOrderClient"
          :custom-extensions="['RfisExtension']"
          :extension-options="[]"
          :extensions="['Autodesk.DocumentBrowser', 'Autodesk.VisualClusters']"
          :models="buildOrderModels"
          ant-toolbar-prefix="build-order"
          style="height: calc(100% - 46px)"
          @modelsRendered="viewerRendered"
        >
          <template #ant-forge-toolbar-actions>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  :color="toggleBuildOrderPlayer ? 'primary' : ''"
                  icon
                  tile
                  v-bind="attrs"
                  @click="toggleBuildOrderPlayer = !toggleBuildOrderPlayer"
                  v-on="on"
                >
                  <v-icon> mdi-play</v-icon>
                </v-btn>
              </template>
              <span>Build order player</span>
            </v-tooltip>
          </template>

          <template #forge-overlay-container>
            <transition name="fade-in">
              <div
                v-if="toggleBuildOrderPlayer"
                class="build-order-player-container"
              >
                <div class="ant-glass-background build-order-player">
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        :color="colorOrderToggle ? 'primary' : ''"
                        class="mr-2"
                        icon
                        v-bind="attrs"
                        @click="colorBuildOrderInModel"
                        v-on="on"
                      >
                        <v-icon dense> mdi-format-color-fill</v-icon>
                      </v-btn>
                    </template>
                    <span>Color order in model</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        :color="configureBuildOrder ? 'primary' : ''"
                        class="mr-2"
                        icon
                        v-bind="attrs"
                        @click="loadBuildOrderSelection"
                        v-on="on"
                      >
                        <v-icon> mdi-cog-transfer</v-icon>
                      </v-btn>
                    </template>
                    <span>Configure build order</span>
                  </v-tooltip>

                  <v-btn
                    class="mr-1"
                    color="primary"
                    dark
                    depressed
                    fab
                    x-small
                    @click="isBuildOrderPlaying = !isBuildOrderPlaying"
                  >
                    <v-icon dense>
                      {{ isBuildOrderPlaying ? 'mdi-pause' : 'mdi-play' }}
                    </v-icon>
                  </v-btn>
                  <v-slider
                    v-model="playerSlider"
                    :max="maxBuildOrder[maxBuildOrder.length - 1]"
                    :step="playerInterval"
                    hide-details
                    @input="renderBuildOrder"
                  />
                  {{ playerSlider }}
                  <div class="mx-2">
                    <v-edit-dialog large @save="updatePlayerInterval()">
                      <v-tooltip top>
                        <template #activator="{ on, attrs }">
                          <v-icon color="primary" v-bind="attrs" v-on="on">
                            mdi-debug-step-over
                          </v-icon>
                        </template>
                        <span>Edit player interval</span>
                      </v-tooltip>
                      {{ playerInterval }}
                      <template #input>
                        <v-text-field
                          v-model="updatedInterval"
                          label="Interval"
                          single-line
                          type="number"
                        />
                      </template>
                    </v-edit-dialog>
                  </div>
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        class="mr-2"
                        icon
                        small
                        v-bind="attrs"
                        @click="revisionDialog = true"
                        v-on="on"
                      >
                        <v-icon dense> mdi-history</v-icon>
                      </v-btn>
                    </template>
                    <span>Create revision</span>
                  </v-tooltip>
                </div>
              </div>
            </transition>
          </template>
        </forge-viewer>
      </div>
      <revision-dialog
        :display-dialog="revisionDialog"
        :table="{
          project: project.id,
          id: buildOrderData.CFFA_FORGE_BUILD_ORDER.id,
        }"
        @closeDialog="
          revisionDialog = false;
          fetchBuildOrderRevisions;
        "
      />
    </div>
  </div>
  <div v-else class="d-flex align-center justify-center">
    <ant-loading />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { Portal } from 'portal-vue';
import AntToolbarContainer from '@/components/AntToolbarContainer';
import { BUILD_ORDER } from '@/modules/modules';
import DynamicDataTable from '@/components/DynamicDataTable';
import RevisionDialog from '@/components/Dialogs/RevisionDialog';
import AntLoading from '@/components/AntLoading.vue';
import PanelResizable from '@/components/Project/PanelResizable.vue';
import {
  getObjectsWithMappingLevel,
  hexToVector4,
  pSBC,
} from '@/components/Modules/Daiwa-House-Modular-Europe/utils/DHME+utils';

export default {
  name: 'BuildOrder',
  components: {
    PanelResizable,
    AntLoading,
    Portal,
    AntToolbarContainer,
    RevisionDialog,
    DynamicDataTable,
    ForgeViewer: () =>
      import(
        /* webpackChunkName: "build-order" */ '../components/Modules/Forge/ForgeViewer'
      ),
  },
  data: () => {
    return {
      headers: [
        {
          text: 'SBS',
          value: 'SBS',
        },
        {
          text: 'Order',
          value: 'build_order',
          align: 'end',
          hasSlot: true,
        },
      ],
      configureBuildOrder: false,
      isBuildOrderPlaying: false,
      playerSlider: 0,
      playerInterval: 1,
      updatedInterval: 1,

      toggleBuildOrderPlayer: false,

      propertiesOfModels: [],
      colorOrderToggle: false,
      revisionDialog: false,
      sbsModelMapping: ['Text', 'ant-sbscode'],
      selectionBoundEvent: null,
    };
  },
  computed: {
    ...mapGetters([
      'buildOrderData',
      'buildOrderStatus',
      'buildOrderRevisions',
      'buildOrderModuleOrderRecords',
      'buildOrderClient',
      'buildOrderModels',
      'project',
    ]),
    maxBuildOrder() {
      return this.buildOrderData.CFFA_FORGE_BUILD_ORDER.records
        .map((item) => item.build_order)
        .sort(function (a, b) {
          return a - b;
        });
    },
    forgeViewer() {
      return this.$refs['forge-viewer'].viewerService.Viewer3D;
    },
    forgeViewerService() {
      return this.$refs['forge-viewer'].viewerService;
    },
    modelProperties() {
      return this.$refs['forge-viewer'].modelObjects.flatMap(
        (item) => item.properties
      );
    },
  },
  watch: {
    isBuildOrderPlaying(bool) {
      if (bool) {
        this.unloadBuildOrderSelection();
        this.playBuildOrder();
      }
    },
  },
  mounted() {
    this.fetchModuleData();
  },
  methods: {
    viewerRendered(value) {
      this.$refs['forge-viewer'].setExternalMapping(value.myData.urn);
    },
    fetchModuleData() {
      this.$store.dispatch('fetchBuildOrderModuleData', {
        projectId: this.project.id,
        moduleId: this.project.modules.find(
          (module) => module.route === BUILD_ORDER
        ).id,
        sessionId: this.$route.params.sessionId ?? null,
      });
    },
    fetchBuildOrderRevisions() {
      this.$store.dispatch('fetchBuildOrderRevisions');
    },
    fetchBuildOrderRevision(revision) {
      this.$store.dispatch('fetchBuildOrderRevisionData', {
        revision: revision,
      });
    },
    updatePlayerInterval() {
      this.playerInterval = parseInt(this.updatedInterval);
    },
    playBuildOrder() {
      if (
        this.isBuildOrderPlaying &&
        this.playerSlider < this.maxBuildOrder[this.maxBuildOrder.length - 1]
      ) {
        this.playerSlider += this.playerInterval;
        this.renderOrderInModel();
        let that = this;
        setTimeout(function () {
          that.playBuildOrder();
        }, 1500);
      } else {
        this.isBuildOrderPlaying = false;
      }
    },
    renderBuildOrder() {
      if (this.playerSlider === 0) {
        this.$refs['forge-viewer'].clearSearch();
      } else {
        this.renderOrderInModel();
      }
    },

    renderOrderInModel() {
      this.forgeViewer.hideAll();
      this.forgeViewer.setGhosting(true);

      this.forgeViewer.clearSelection();
      this.forgeViewer.clearThemingColors();

      this.buildOrderModels
        .filter((r) => r.enabled)
        .forEach((modelRecord) => {
          const model = this.forgeViewer.impl
            .modelQueue()
            .getModels()
            .concat()
            .find((model) => modelRecord.urn.includes(model.myData.urn));

          let modelObjects = this.$refs['forge-viewer'].modelObjects.find(
            (item) => item.urn === modelRecord.urn
          ).properties;

          let sbsLevel = modelRecord.sbs_parameter.split('.');

          let objects = getObjectsWithMappingLevel(modelObjects, sbsLevel);

          let previousObjects = objects.filter((object) =>
            this.buildOrderModuleOrderRecords
              .filter((item) => item.build_order < this.playerSlider)
              .some(
                (record) =>
                  sbsLevel.reduce((o, i) => o[i], object.properties) ===
                  record.SBS
              )
          );

          this.forgeViewer.show(
            this.$refs['forge-viewer'].mapExternalIdsToObjectIdsMultiModel(
              model.myData.urn,
              previousObjects.map((object) => object.externalId)
            )
          );
          let currentObjects = objects.filter((object) =>
            this.buildOrderModuleOrderRecords
              .filter((item) => item.build_order === this.playerSlider)
              .some(
                (record) =>
                  sbsLevel.reduce((o, i) => o[i], object.properties) ===
                  record.SBS
              )
          );

          this.forgeViewer.show(
            this.$refs['forge-viewer'].mapExternalIdsToObjectIdsMultiModel(
              model.myData.urn,
              currentObjects.map((object) => object.externalId)
            )
          );

          this.forgeViewer.select(
            this.$refs['forge-viewer'].mapExternalIdsToObjectIdsMultiModel(
              model.myData.urn,
              currentObjects.map((object) => object.externalId)
            )
          );

          let futureObjects = objects.filter((object) =>
            this.buildOrderModuleOrderRecords
              .filter((item) => item.build_order > this.playerSlider)
              .some(
                (record) =>
                  sbsLevel.reduce((o, i) => o[i], object.properties) ===
                  record.SBS
              )
          );

          this.forgeViewer.hide(
            this.$refs['forge-viewer'].mapExternalIdsToObjectIdsMultiModel(
              model.myData.urn,
              futureObjects.map((object) => object.externalId)
            )
          );
        });
    },

    findObjectInModel(item) {
      this.$refs['forge-viewer'].searchInModel(item.SBS);
      this.$refs['forge-viewer'].searchPhrase = item.SBS;
    },
    loadBuildOrderSelection() {
      if (!this.configureBuildOrder) {
        this.configureBuildOrder = true;
        this.selectionBoundEvent = this.buildOrderSelectionEvent.bind(this);
        // eslint-disable-next-line no-undef
        this.forgeViewer.addEventListener(
          Autodesk.Viewing.SELECTION_CHANGED_EVENT,
          this.selectionBoundEvent
        );
      } else {
        this.unloadBuildOrderSelection();
      }
    },
    unloadBuildOrderSelection() {
      if (this.configureBuildOrder) {
        this.configureBuildOrder = false;
        this.forgeViewer.removeEventListener(
          Autodesk.Viewing.SELECTION_CHANGED_EVENT,
          this.selectionBoundEvent
        );
        this.forgeViewer.clearSelection();
        this.forgeViewer.clearThemingColors();
        this.selectionBoundEvent = null;
      }
    },

    buildOrderSelectionEvent() {
      let selection = this.forgeViewer.getSelection();

      let externalIds =
        this.$refs['forge-viewer'].getExternalIdsByNewObjectIds(selection);

      this.buildOrderModels
        .filter((r) => r.enabled)
        .some((modelRecord) => {
          let modelObjects = this.$refs['forge-viewer'].modelObjects.find(
            (item) => item.urn === modelRecord.urn
          ).properties;

          let sbsLevel = modelRecord.sbs_parameter.split('.');

          let objectsSelection = modelObjects.filter((object) => {
            return externalIds.some((id) => id === object.externalId);
          });

          let objects = getObjectsWithMappingLevel(objectsSelection, sbsLevel);

          let object = objects[0];

          if (object) {
            this.$store.dispatch(
              'setSBSOrder',
              sbsLevel.reduce((o, i) => o[i], object.properties)
            );
            return true;
          }
        });
    },
    updateBuildOrder(recordId, value) {
      let body = {
        project: {
          id: this.project.id,
        },
        table: {
          id: this.buildOrderData.CFFA_FORGE_BUILD_ORDER.id,
        },
        record: {},
      };

      body.record.build_order = value;

      this.$store.dispatch('updateBuildOrderRecord', { recordId, body });
    },

    resetBuildOrder() {
      if (confirm('Are you sure you want to reset the build order')) {
        this.$store.dispatch('resetBuildOrder').then(() => {
          this.$store.commit('showNotification', {
            color: 'success',
            content: 'Reset build order successfully',
          });
        });
      }
    },

    colorBuildOrderInModel() {
      this.colorOrderToggle = !this.colorOrderToggle;
      if (this.colorOrderToggle) {
        this.forgeViewer.clearThemingColors();

        let sorted = [...this.buildOrderModuleOrderRecords].sort(
          function (a, b) {
            return a.build_order - b.build_order;
          }
        );

        this.buildOrderModels
          .filter((r) => r.enabled)
          .forEach((modelRecord) => {
            const model = this.forgeViewer.impl
              .modelQueue()
              .getModels()
              .concat()
              .find((model) => modelRecord.urn.includes(model.myData.urn));

            let modelObjects = this.$refs['forge-viewer'].modelObjects.find(
              (item) => item.urn === modelRecord.urn
            ).properties;

            let sbsLevel = modelRecord.sbs_parameter.split('.');
            let objects = getObjectsWithMappingLevel(modelObjects, sbsLevel);

            sorted.forEach((record, index) => {
              let sbsObjects = objects.filter(
                (object) =>
                  sbsLevel.reduce((o, i) => o[i], object.properties) ===
                  record.SBS
              );

              let color = hexToVector4(
                pSBC(
                  (Math.floor((index / sorted.length) * 200) - 95) / 100,
                  this.$vuetify.theme.themes.light.primary
                )
              );

              let objectIds = this.$refs[
                'forge-viewer'
              ].mapExternalIdsToObjectIdsMultiModel(
                model.myData.urn,
                sbsObjects.map((object) => object.externalId)
              );

              objectIds.forEach((id) => {
                this.forgeViewer.setThemingColor(id, color, model, true);
              });
            });
          });
      } else {
        this.forgeViewer.clearThemingColors();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.configure-btn {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.build-order-player-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 100px;
  left: calc(50% - 200px);
  z-index: 2;

  .build-order-player {
    display: flex;
    align-items: center;
    width: 400px;
    margin: 10px;
    padding: 10px 12px;
    z-index: 1;
  }
}

.configure-build-order {
  position: absolute;
  left: calc(50% - 86px);
  top: 10px;
  z-index: 100;
  margin: auto;
}
</style>
